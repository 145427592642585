export default {
  id: null, 
  name: null, 
  surname: null, 
  gender: null, 
  birth_year: null, 
  school: null, 
  school_class: null,
  
  is_questionnaire_shown: null, 
  available_questions: [], 
  answers: [],

  dietary_restrictions: null, 
  health_information: null, 
  educational_needs: null, 
  is_dietary_restrictions_shown: null, 
  is_educational_needs_shown: null, 
  is_health_information_shown: null
}